body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1200px) {
  .App {
    width: 40em !important;
    margin: auto;
  }
}

/* @media (min-width: 992px) {
  .App {
    max-width: 720px;
  }
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cyrillic-table {
  color: white;
}

.cyrillic-table td,
.cyrillic-table thead {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.cyrillic-table td {
  padding-bottom: 0.7em;
  padding-top: 0.5em;
}

.cyrillic-table th {
  border-right: 1px solid black;
  border-top: 1px solid black;
}

.cyrillic-table tbody {
  border-bottom: 1px solid black;
}
